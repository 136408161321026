<template>
    <div id="header">
    </div>
</template>

<style scoped>
#header {
    border-bottom: solid 1px #ccc;
    position: fixed;
    left: 280px;
    width: 100%;
    height: 60px;
}
</style>